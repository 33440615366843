.App {
  text-align: center;
}

.footer{
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  text-align: center;
  color:#999;
  padding: 20px;
}

.App-logo {  
  max-width: 360px;
  pointer-events: none;
}


.App-header {
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.app-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 570px;
}

.social-wrapper{
  width: 114px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.social-logo{
  width: 40px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .App-header{
    padding: 0px;
  }

  .app-content{
    padding: 0px 10px;
  }
  .app-content p{

    font-size: 0.9em;
    margin: 10px 20px 20px;
  }
  .footer{
    font-size: 0.8em;

  }


.App-logo {  
  max-width: 260px;
  pointer-events: none;
}
}